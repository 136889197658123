var site = site || {};
var generic = generic || {};

(function ($, Drupal) {
  Drupal.behaviors.cliniqueLoyaltyPopupV1 = {
    attach: function (context) {
      // Set cookie while login to myaccount to show the loyalty popup
      $('.signin-submit, .signup-submit, .signin_submit .form-submit, .loyalty_cancel', context).on(
        'click',
        function () {
          $.cookie('LOYALTY_POPUP', '1', {
            path: '/',
            expires: 60
          });
        }
      );

      if (!$.cookie('LOYALTY_POPUP')) {
        return;
      }

      $(document).on('user.loaded', function () {
        var signedIn = parseInt(site.userInfoCookie.getValue('signed_in'));
        var persistentCookie = Drupal.ELB.getJSONCookie('persistent_user_cookie');
        var pc_email_optin = parseInt(persistentCookie.pc_email_optin);
        var isLoyaltyUser = parseInt(persistentCookie.is_loyalty_member);

        if (signedIn === 0 || isLoyaltyUser === 1) {
          return;
        }
        $.removeCookie('LOYALTY_POPUP', { path: '/' });
        var rendered = $('.loyalty_content', context).html();

        generic.overlay.launch({
          content: rendered,
          width: 980,
          height: 630,
          cssClass: 'product-fave-overlay-cbox',
          onShow: function () {
            var isGdpr = $('#loyalty--popup__gdpr--section', context);
            var loyaltyGdprContent = $(
              '#loyalty--popup__gdpr--section .loyalty--popup__gdpr--content',
              context
            );
            if ($('#loyalty_popover__post_signin_checkbox').length !== 0) {
              $(document).on('click', '#clinique_loyalty_popup_submit', function (e) {
                e.preventDefault();
                if ($('#loyalty_popover__post_signin_checkbox').is(':checked')) {
                  $('#clinique_loyalty_join_popup_form').trigger('submit');
                } else {
                  $('#clinique_loyalty_join_popup_form .error').removeClass('hidden');
                }
              });
            }
            if (isGdpr) {
              isGdpr.on('mouseenter mouseleave', function (e) {
                e.preventDefault();
                loyaltyGdprContent.toggleClass('hidden');
              });
            }
          }
        });
        if (pc_email_optin === 1) {
          $('.loyalty-post-signin-form-field-email-promotion').hide();
        }
      });
    }
  };
})(jQuery, Drupal);
